<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader">
            <div class="tip-form mt-1">
                <base-input name="description" label="Description*" :rules="{ custom_required: true }">
                    <textarea class="form-control" placeholder="Description" v-model="form.description">
                    </textarea>
                </base-input>
                <base-select name="status" label="Status*" v-model="form.status" :options="statusOptions"
                    :rules="{ custom_required: true }">
                </base-select>
            </div>
            <div class="text-right mb-2">
                <base-button 
                    type="gray"
                    size="xl"
                    class="px-2 mr-3"
                    :disabled="loader"
                    @click="$emit('cancel')">
                        Cancel
                    </base-button>
                <base-button native-type="submit" type="dark-blue" size="xl" :disabled="loader">
                    {{ formType }}
                </base-button>
            </div>
        </form>
    </validation-observer>
</template>

<script>
import { constants } from "@/util/constants";

export default {
    props: {
        tip: Object,
        loader: {
            type: Boolean,
            default: false
        },
        formType: {
            type: String,
            default: 'Save'
        }
    },
    components: {
    },
    data() {
        return {
            form: {
                description: '',
                status: 0,
            },
            statusOptions: constants.common.statusOptions
        };
    },
    mounted() {
        this.setValues(this.tip);
    },
    watch: {
        'tip': function (newValue) {
            this.setValues(newValue);
        }
    },
    methods: {
        setValues(value) {
            this.form.description = value?.description ?? '';
            this.form.status = value?.status ?? 1;
        },
        async onSubmit() {
            if (this.formType == 'Update') this.$emit('update', { fd: this.form, id: this.tip.id });
            else this.$emit('store', this.form);
        }
    }
};
</script>

<style lang="scss">
.tip-form {
    .form-group {
        margin-bottom: 0.65rem !important;
    }

    input {
        &.form-control {
            height: calc(1.5em + 1.25rem + 2px);
        }
    }
}
</style>