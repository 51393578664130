<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button />
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Tips</h1>
      <div class="d-flex justify-content-end">
        <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect"
          @click="() => showAddEditModal()">Add New</el-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <div class="d-flex" v-if="!selectedRows.length">
          <base-input class="d-inline-block width-285" label="Search" placeholder="Search by description"
            v-model="request.search" type="search" @input="filterChange" />
        </div>
        <div v-else id="select-option-container" class="mt-4 ml-4">
          <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button" @click="toggleStatus(true)"
            :loading="loaders.checkboxButton.Activate">Activate
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button" @click="toggleStatus(false)"
            :loading="loaders.checkboxButton.DeActive">Deactivate
          </base-button>
        </div>
      </div>
      <el-table row-key="id" class="tips-table" header-row-class-name="thead-light" width="100%"
        :data="response.tips" @selection-change="sectionListMultipleSelect" v-loading="loaders.tips" ref="tipsTable">
        <el-table-column prop="selected" type="selection" width="50">
        </el-table-column>
        <el-table-column label="DESCRIPTION" min-width="800">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.description }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CREATED" min-width="120">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.created | getCreatedAt }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" min-width="100">
          <template slot-scope="scope">
            <span :class="['status', getStatusClass(scope.row.status)]">
              <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
              {{ getStatus(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="180">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip content="View Tip" placement="top">
              <img class="pointer mr-2" src="/img/eye.svg" @click="openShowModal(row)" alt="">
            </el-tooltip>
            <el-tooltip content="Edit Tip" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" @click="edit(row)" alt="">
            </el-tooltip>
            <el-tooltip content="Delete Tip" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/delete.svg" @click="promptRemove(row)" alt="">
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <custom-pagination class="pagination-no-border float-right" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="pagination.totalPages" @input="changePage" />
        </div>
      </div>
    </div>
    <div class="modals">
      <remove-modal :loader="loaders.removeModal" :modal-text="removeModalText" :removeId="removeId"
        @onRemove="deleteRequest" />
      <add-edit-dialog :visible.sync="modals.addEdit" @closeDialog="closeAddEditModal" :tip="selectedTip"
        @added="getTipsListing" @updated="getTipsListing" :form-type="formType"/>
      <show-dialog :visible.sync="modals.show" @closeDialog="closeShowModal()" @edit=edit :tip="selectedTip" />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { Table, TableColumn } from "element-ui"
import CustomPagination from "@/views/Components/Pagination/CustomPagination"
import ShowDialog from "@/views/Components/SuperAdmin/Tips/ShowDialog";
import AddEditDialog from "@/views/Components/SuperAdmin/Tips/AddEditDialog";
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "tips",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton,
    ShowDialog,
    AddEditDialog
  },
  data() {
    return {
      loaders: {
        tips: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      request: {
        search: ''
      },
      response: {
        tips: [],
      },
      pagination: {
        currentPage: 1,
        totalPages: 1,
        perPage: 10
      },
      modals: {
        show: false,
        addEdit: false
      },
      selectedTip: null,
      formType: 'Save'
    }
  },
  mounted() {
    this.getTipsListing()
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getCreatedAt: function (date) {
      return moment.utc(date).local().format('DD-MMM-YYYY')
    }
  },
  methods: {
    filterChange() {
      this.pagination.currentPage = 1;
      this.getTipsListing()
    },
    /**
     * Change page
     * @param item
     */
    changePage(item) {
      this.pagination.currentPage = item
      this.getTipsListing()
    },

    /**
     * Get Tips Listing
     */
    getTipsListing() {
      let vm = this
      vm.loaders.tips = true
      const payload = {
        page: vm.pagination.currentPage,
        search: vm.request.search,
      }
      vm.$store.dispatch('TipsModule/_get', payload)
        .then(response => {
          vm.response.tips = response.data.data.data
          vm.pagination.currentPage = response.data.data.current_page
          vm.pagination.totalPages = response.data.data.total
          vm.pagination.perPage = response.data.data.per_page;
          vm.loaders.tips = false
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            vm.loaders.tips = false
            const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.$notify.error({
              title: 'Tips',
              message: message
            })
          }
        })
    },

    /**
     * Open show modal
     * @param item
     */
    openShowModal(item) {
      this.selectedTip = item;
      this.modals.show = true;
    },

    /**
     * Close show modal
     */
    closeShowModal() {
      this.selectedTip = null;
      this.modals.show = false;
    },

    /**
     * Edit Tip
     * @param item
     */
    edit(item) {
      this.modals.show = false;
      this.selectedTip = item;
      this.showAddEditModal('Update')
    },

    showAddEditModal(formType = 'Save') {
      this.formType = formType;
      this.modals.addEdit = true;
    },

    closeAddEditModal() {
      this.selectedTip = null;
      this.modals.addEdit = false;
    },
    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle the active button event
     */
    toggleStatus(status) {
      let vm = this;
      Swal.fire({
        text: 'Are you sure you want to update status?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#832C44',
        allowOutsideClick: false,
      }).then((response) => {
        if (response.isConfirmed) {
          vm.statusUpdateRequest(status)
        } else {
          vm.$refs.tipsTable.clearSelection();
        }
      })
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Tip.'
      this.removeId = row.id
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows.map(el => el.id);
    },

    /**
     * Delete Tip(s)
     * @param data
     */
    deleteRequest(id) {
      let vm = this
      vm.loaders.tips = true
      vm.$store.dispatch('TipsModule/_delete', id)
        .then(response => {
          vm.removeId = []
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: response.data.message
          })
          vm.getTipsListing();
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Tips',
            message: message
          })
        })
        .finally(()=> {
          vm.loaders.tips = false
        })
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(status) {
      let vm = this
      vm.loaders.tips = true
      const payload = {
        ids: vm.selectedRows,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('TipsModule/_toggleStatus', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: response.data.message
          })
          vm.getTipsListing()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Tips status',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.tips = false;
        })
    },
  }
}
</script>

<style scoped></style>
