<template>
    <el-dialog v-loading="loader" :title="title" :visible.sync="visible" width="500px" :before-close="handleClose">
        <tip-form ref="form" :tip="tip" @update="update" @store="store" @cancel="handleClose" :form-type="formType" />
    </el-dialog>
</template>

<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import TipForm from "@/views/Components/SuperAdmin/Tips/Form";
export default {
    props: ['visible', 'tip', 'formType'],
    mixins: [generateErrorMessageMixin],
    components: {
        TipForm
    },
    data() {
        return {
            loader: false,
        };
    },
    computed: {
        title() {
            return this.tip ? 'Edit' : 'Add New';
        }
    },
    methods: {
        handleClose() {
            let validation = this.$refs.form.$refs.formValidator;
            validation.reset();
            this.$refs.form.setValues({});
            this.$emit('closeDialog')
        },
        async update({ fd, id }) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('TipsModule/_update', { fd, id });
                let { data: { data, message } } = response;
                this.$notify.success({
                    title: 'Tips',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('updated');
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error, 'Tips', validation)
            }
            this.loader = false;
        },
        async store(fd) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('TipsModule/_store', fd);
                let { data: { data, message } } = response;
                this.$notify.success({
                    title: 'Tips',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('added');
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error, 'Tips', validation)
            }
            this.loader = false;
        }
    },
};
</script>