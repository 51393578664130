<template>
  <el-dialog
    :visible.sync="visible"
    title="View Info"
    width="500px"
    :before-close="handleClose"
    class="tips-show"
  >
    <div class="border-top" v-if="tip">
      <div class="mt-4 d-flex align-items-end justify-content-between">
        <p class="custom-status text-capitalize" :class="getStatusClassName(tip.status)">
          {{ getStatusClassName(tip.status) }}
        </p>
        <base-button
          type="dark-blue"
          class="py-1"
          outline
          size="xl"
          @click="$emit('edit', tip)"
          >Edit</base-button
        >
      </div>
      <div class="__details mt-3">
        <h3 class="__description">{{ tip.description }}</h3>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["visible", "tip"],
  methods: {
    handleClose() {
      this.$emit("closeDialog");
    },
    getStatusClassName(status) {
        return status ? "active" : "inactive"
    }
  },
};
</script>

<style scoped lang="scss">
.tips-show {
  .__details {
    .__description {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>